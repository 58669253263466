import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import Cookies from "js-cookie";
import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatSalary } from "../../../helpers/conditional_rendering";
import {
  getCompanyDetails,
  getCompanyLogoImage,
} from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  bookmarkJob,
  unBookmarkJob,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignUpOrigin,
} from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/job_type";
import {
  ArrowIconStyled,
  BookmarkButton,
  BottomChipsContainer,
  BottomContainer,
  CardVisibleLayer,
  CardWrapper,
  CompanyDetailsContainer,
  CompanyLogo,
  DividerStyled,
  DollarIcon,
  GlobalIcon,
  HighlightContainer,
  HighlightedContainer,
  HighlightIcon,
  JobCardSubContainer,
  JobCompanyText,
  JobDetailsContainer,
  JobDetailText,
  JobIcon,
  JobIconMobile,
  JobSubDetailsContainer,
  JobTitleText,
  JobTypeChip,
  KeywordChip,
  LinkWrapper,
  LocationIcon,
  MobileApplyButton,
  MobileApplyContainer,
  MobileChipContainer,
  MobileDetailChip,
  MobileSimilarJobsButton,
  PostedTimeText,
  SaveIcon,
  SimilarJobsButton,
  SimilarJobsText,
  SpotlightIcon,
  TooltipWrapper,
  TopContainer,
  UnsaveIcon,
  VisibilityChip,
  VisibilityContainer,
  Wrapper,
} from "./styles";

function AccordionJobCard(props) {
  const {
    job,
    onHoverEnter,
    onJobCardClick,
    onApplyJobClick,
    onSimilarJobsClick,
  } = props;

  const dispatch = useDispatch();

  const isLoggedIn = checkAuthentication();

  const isClient = useIsClient();
  const isMounted = useRef(true);

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const hoverJob = useSelector((state) => state.jobs.hoverJob);

  const [bookmark, setBookmark] = useState(job?.bookmark);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [applyButtonHover, setApplyButtonHover] = useState(false);

  const jobDiscovery = "";
  const searchTerm = "";

  /*CONDITIONAL LOGIC */
  const activeHoverJob = hoverJob?.id === job?.id;

  const jobSlotMax = job?.jobSlotType?.includes("job_slot_max");

  const jobSlotUpgrade = jobSlotMax || job?.jobSlotType === "job_slot_plus";

  const jobSpotlight = job?.spotlight || jobSlotUpgrade;

  const chipExist =
    (isClient || isMounted.current) &&
    !isMobile &&
    (job?.spotlight ||
      job?.boosted ||
      jobSlotUpgrade ||
      job?.globalHirePreferences?.rightToWorkOpenToSponsor);

  const boosted = job?.spotlight || job?.boosted || jobSlotUpgrade;

  const disabledApplyButton =
    job?.haveApplied || job?.haveApplied === undefined;

  const disabledBookmarkButton =
    (!isClient && (job?.bookmark || job?.bookmark === undefined)) ||
    bookmarkLoading;
  const isExternalJob =
    job?.category === "scraped" || job?.category === "aggregated";

  /* DYNAMIC TEXT LOGIC */
  const bookmarkIcon = bookmark ? <UnsaveIcon /> : <SaveIcon />;

  const applyText = job?.haveApplied
    ? "Applied"
    : isMobile
    ? isExternalJob
      ? "Apply"
      : "Apply Now"
    : applyButtonHover
    ? "Apply Now!"
    : "Click here to apply!";

  /* FUNCTION LOGIC */
  function handleCompanyClick(event, companyDetails) {
    event.stopPropagation();
    event.preventDefault();

    Cookies.set("click-source", "job_listing_card");

    if (companyDetails?.slug) {
      window.open(`/companies/${companyDetails?.slug}`, "_blank");
    }
  }

  function handleBookmarkJob(e) {
    e.stopPropagation();
    e.preventDefault();

    // Redirect to sign in if user is not logged in
    if (!isLoggedIn) {
      return dispatch(updateSelectedJob(job)).then(() => {
        dispatch(updateSignUpOrigin("save_job"));
        dispatch(updateShowSignInModalStatus(true));
      });
    }

    if (bookmarkLoading) {
      return;
    }

    setBookmarkLoading(true);

    let params = {
      jobId: job?.id,
      canCancel: false,
      origin: "job-list",
    };

    // Toggle bookmark status
    if (!bookmark) {
      dispatch(bookmarkJob(params)).then((res) => {
        if (res?.type === types.BOOKMARK_JOB_SUCCEED) {
          toast.success("Job bookmarked");
          setBookmark(true);
          setBookmarkLoading(false);
        }
      });
    } else if (bookmark) {
      dispatch(unBookmarkJob(params)).then((res) => {
        if (res?.type === types.UNBOOKMARK_JOB_SUCCEED) {
          toast.success("Job unbookmarked");
          setBookmark(false);
          setBookmarkLoading(false);
        }
      });
    }
  }

  function trackImpressionInView(job) {
    // POSTHOG JOB IMPRESSION TRACKING
    sendTrackingEvent({
      event: "CE_job-impression",
      "job-id": job?.id,
      origin: "job_list",
      "job-discovery": jobDiscovery,
      search_term: searchTerm ?? "",
    });
  }

  function getTime(job) {
    let activeAtDate = job?.activeAt || job?.active_at;
    let jobActiveAt = moment(activeAtDate, "YYYYMMDD");
    let timeNow = moment(moment(), "YYYYMMDD");

    const time =
      timeNow.diff(jobActiveAt, "months") > 3
        ? null
        : timeNow.diff(jobActiveAt, "months") > 1
        ? timeNow.diff(jobActiveAt, "months") + " months ago"
        : moment(activeAtDate).fromNow();

    return time;
  }

  /* MEMO SECTION */
  const salary = useMemo(() => {
    return job?.salary === "Undisclosed" || job?.salary === "Undefined"
      ? "Undisclosed"
      : formatSalary(job?.salary, job?.crossBorderData);
  }, [job?.salary, job?.crossBorderData]);

  const companyDetails = useMemo(() => {
    return getCompanyDetails(job);
  }, [job?.company, job?.crossBorderData]);
  const companyName = companyDetails?.name;

  const companyLogo = useMemo(() => {
    return getCompanyLogoImage(companyDetails);
  }, [companyDetails]);

  const jobPostedTime = useMemo(() => {
    return getTime(job) !== null ? getTime(job) : null;
  }, [job]);

  return (
    <Wrapper>
      <LinkWrapper href={`/jobs/${job?.slug}`}>
        <TooltipWrapper followCursor title={"Click to view job"}>
          <CardWrapper
            onClick={(e) => onJobCardClick(e, job)}
            onMouseEnter={() => onHoverEnter(job)}
            $selected={activeHoverJob}
            $highlight={job?.boosted}
            $spotlight={jobSpotlight}
          >
            {boosted && (
              <HighlightContainer
                $highlight={job?.boosted}
                $spotlight={jobSpotlight}
              />
            )}

            <CardVisibleLayer>
              <TopContainer $boosted={boosted}>
                {chipExist && (
                  <HighlightedContainer>
                    <VisibilityContainer>
                      {job?.boosted && (
                        <VisibilityChip $highlight>
                          <HighlightIcon />
                        </VisibilityChip>
                      )}
                      {jobSpotlight && (
                        <VisibilityChip $spotlight>
                          <SpotlightIcon />
                        </VisibilityChip>
                      )}
                      {job?.globalHirePreferences?.rightToWorkOpenToSponsor && (
                        <VisibilityChip $global>
                          <GlobalIcon />
                          Global hire
                        </VisibilityChip>
                      )}
                    </VisibilityContainer>
                    <BookmarkButton
                      onClick={handleBookmarkJob}
                      disabled={disabledBookmarkButton}
                    >
                      {bookmarkIcon}
                    </BookmarkButton>
                  </HighlightedContainer>
                )}

                <JobDetailsContainer>
                  <InView
                    style={{ width: "100%" }}
                    onChange={(inView) =>
                      inView === true && trackImpressionInView(job)
                    }
                  >
                    <JobTitleText>{job?.title}</JobTitleText>
                  </InView>

                  {!isMobile && (
                    <JobSubDetailsContainer>
                      <JobDetailText>
                        {isClient && <DollarIcon />}
                        {salary}
                      </JobDetailText>
                      <JobDetailText>
                        {isClient && <LocationIcon />}
                        {job?.stateRegion}
                      </JobDetailText>
                    </JobSubDetailsContainer>
                  )}

                  {isMobile && (
                    <BookmarkButton
                      onClick={handleBookmarkJob}
                      disabled={disabledBookmarkButton}
                    >
                      {bookmarkIcon}
                    </BookmarkButton>
                  )}
                </JobDetailsContainer>

                <JobCardSubContainer>
                  <CompanyDetailsContainer>
                    <TooltipWrapper
                      followCursor
                      title={"Click to view company profile"}
                    >
                      <CompanyLogo
                        src={companyLogo}
                        alt={companyName}
                        loading={"lazy"}
                        $placeholder={!companyDetails?.logo}
                        onClick={(e) => handleCompanyClick(e, companyDetails)}
                      />
                    </TooltipWrapper>
                    <JobCompanyText>{companyName}</JobCompanyText>
                    {!chipExist && !isMobile && (
                      <BookmarkButton
                        onClick={handleBookmarkJob}
                        disabled={disabledBookmarkButton}
                      >
                        {bookmarkIcon}
                      </BookmarkButton>
                    )}
                  </CompanyDetailsContainer>

                  {isMobile && (
                    <>
                      <MobileChipContainer>
                        <MobileDetailChip type={"jobType"}>
                          {job?.jobType}
                        </MobileDetailChip>
                        <MobileDetailChip type={"state"}>
                          {job?.stateRegion}
                        </MobileDetailChip>
                        <MobileDetailChip type={"salary"}>
                          {job?.salary === "Undisclosed" ||
                          job?.salary === "Undefined"
                            ? "Undisclosed"
                            : formatSalary(job?.salary, job?.crossBorderData)}
                        </MobileDetailChip>
                      </MobileChipContainer>
                      <PostedTimeText>{jobPostedTime}</PostedTimeText>
                    </>
                  )}
                </JobCardSubContainer>
              </TopContainer>

              {(!isMobile ||
                (job?.keywordHighlight &&
                  job?.keywordHighlightText?.length > 0)) && (
                <BottomContainer $jobSlotMax={jobSlotMax}>
                  <BottomChipsContainer>
                    {job?.jobType && !isMobile && (
                      <JobTypeChip $jobSlotMax={jobSlotMax}>
                        {job?.jobType}
                      </JobTypeChip>
                    )}
                    {job?.keywordHighlight && (
                      <>
                        {!isMobile && (
                          <DividerStyled
                            orientation="vertical"
                            $jobSlotMax={jobSlotMax}
                          />
                        )}
                        {job?.keywordHighlightText.map((keyword) => (
                          <KeywordChip key={keyword} $jobSlotMax={jobSlotMax}>
                            {keyword}
                          </KeywordChip>
                        ))}
                      </>
                    )}
                  </BottomChipsContainer>
                  {!isMobile && (
                    <PostedTimeText $jobSlotMax={jobSlotMax}>
                      {jobPostedTime}
                    </PostedTimeText>
                  )}
                </BottomContainer>
              )}

              {isMobile && (
                <MobileApplyContainer>
                  <MobileSimilarJobsButton
                    onClick={(e) => onSimilarJobsClick(e, job)}
                  >
                    {isMobile ? <JobIconMobile /> : <JobIcon />}
                    view similar jobs
                  </MobileSimilarJobsButton>
                  <MobileApplyButton
                    onClick={(e) => onApplyJobClick(e, job)}
                    disabled={disabledApplyButton}
                  >
                    {applyText}
                    {isLoggedIn && isClient && isExternalJob && (
                      <ArrowIconStyled />
                    )}
                  </MobileApplyButton>
                </MobileApplyContainer>
              )}
            </CardVisibleLayer>
          </CardWrapper>
        </TooltipWrapper>
      </LinkWrapper>
      {!isMobile && (
        <SimilarJobsButton
          onClick={(e) => onSimilarJobsClick(e, job)}
          onMouseEnter={() => onHoverEnter(job)}
        >
          <JobIcon />
          <SimilarJobsText>
            View <br /> Similar Jobs
          </SimilarJobsText>
        </SimilarJobsButton>
      )}
    </Wrapper>
  );
}

export default AccordionJobCard;
